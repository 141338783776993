import { useState } from "react";
import { Button, Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { BriefApplication, BriefApplicationStatus } from "../../models/brief";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface NewBriefApplicationProps {
  briefApplcation: BriefApplication;
  onSubmit: (b: BriefApplication) => void;
}

export default function EditBriefApplication(props: NewBriefApplicationProps) {
  const { handleSubmit, register, getValues } = useForm<BriefApplication>({ defaultValues: props.briefApplcation });
  const apiCall = useAuthApiCall();
  const [status, setStatus] = useState(props.briefApplcation.status);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (b: BriefApplication) => {
    setIsSubmitting(true);
    let updatedBriefApplication = await apiCall(`brands/${props.briefApplcation.brandId}/briefs/${props.briefApplcation.briefId}/applications/${props.briefApplcation.id}`, 'put', b);
    setIsSubmitting(false);
    props.onSubmit(updatedBriefApplication);
  }

  return (
    <Container style={{ border: '2px #333 solid', borderRadius: 2, padding: '10px 15px 30px 15px', margin: '40px auto' }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container fluid>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="sf-label-regular">Application Status:</Form.Label>
                <select {...register("status", { required: true })} className="form-select" onChange={(e) => setStatus(e.target.value as BriefApplicationStatus)}>
                  {Object.values(BriefApplicationStatus).map((status) => <option key={status} value={status}>{status}</option>)}
                </select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="sf-label-regular">Message:</Form.Label>
                <textarea disabled={true} required style={{ borderRadius: 3, width: '100%', background: 'none', border: '1px #333 solid', minHeight: '200px', padding: 5 }} placeholder="Let the brand know how you can help them get their message out." {...register("message", { required: true })} />
              </Form.Group>
            </Col>
          </Row>

          {
            (status === BriefApplicationStatus.Unsuccessful || status === BriefApplicationStatus.NotEligible) &&
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="sf-label-regular">Rejection Reason:</Form.Label>
                  <select {...register("rejectionReason")} id="rejectionReason" name="rejectionReason" className="form-select" style={{ width: '100%', padding: 10, marginBottom: 20 }}>
                    <option value="">Select a reason for rejection *</option>
                    <option value="Audience demographics don't match campaign requirements">Audience demographics don't match campaign requirements</option>
                    <option value="Seeking different content style or theme">Seeking different content style or theme</option>
                    <option value="Engagement rates below campaign's required threshold">Engagement rates below campaign's required threshold</option>
                    <option value="Follower count below campaign's minimum requirement">Follower count below campaign's minimum requirement</option>
                    <option value="Past collaborations/content not aligned with our brand values">Past collaborations/content not aligned with our brand values</option>
                    <option value="Already secured enough creators for this campaign">Already secured enough creators for this campaign</option>
                    <option value="Other">Other (please specify below)</option>
                  </select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="sf-label-regular">Review Message:</Form.Label>
                    <textarea style={{ borderRadius: 3, width: '100%', background: 'none', border: '1px #333 solid', minHeight: '50px', padding: 5 }} placeholder="Let the creator know more details if needed." {...register("reviewMessage")} />
                </Form.Group>
              </Col>
            </Row>
          }

        </Container>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <Button size="sm" type="submit" variant="primary" disabled={isSubmitting}>
            {isSubmitting && <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." />}Update <FontAwesomeIcon icon={faChevronRight} /> </Button>
        </div>
      </Form>
    </Container >
  );
}
