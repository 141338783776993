import { AppUserProfile } from "./appUser";
import { LookupItem } from "./lookupItem";
import { UserFile } from "./utils";

export enum BriefStatus {
    Draft = "Draft",
    InReview = "InReview",
    Approved = "Approved",
    Live = "Live",
    ChangesRequired = "ChangesRequired",
    Closed = "Closed"
}

export interface Brief {
    id: string
    brandId: string
    brandName: string
    brandWebsite: string
    userId: string
    title: string
    bannerImage?: UserFile,
    attachments?: Array<UserFile>
    description: string
    campaignFrom: Date
    campaignTo: Date
    campaignStartMonth: string
    activityType: Array<LookupItem>
    platforms: Array<LookupItem>
    requiredContent: string
    receiveRates: boolean
    totalCampaignBudget: number
    numberInfluencersRequired?: number
    contentAreas?: Array<LookupItem>
    compensationTypes?: Array<LookupItem>
    briefStatus: BriefStatus
    approvalMessage: string
    requiredCreator?: RequiredCreator
    audienceDemographics?: AudienceDemographics
    dateCreated: Date
    applicationCount: number
    isPaid: boolean
    sentForPayment: boolean
    receiptUrl?: string
    marketingEmails: Array<MarketingEmailList>
}

export interface MarketingEmailList {
    sentTimestamp: Date
    emails: Array<string>
}

export interface RequiredCreator {
    gender: Array<LookupItem>
    contentAreas: Array<LookupItem>
    compensationTypes: Array<LookupItem>
    ages: Array<LookupItem>
    followerCount: Array<LookupItem>
    otherConsiderations: string
}

export interface AudienceDemographics {
    gender: Array<LookupItem>
    ages: Array<LookupItem>
}

export interface BriefApplication {
    id: string
    briefId: string
    brandId: string
    briefTitle: string
    brandName: string
    userId: string
    message: string
    rate: string
    status: BriefApplicationStatus
    reviewMessage: string
    rejectionReason: string
    dateCreated: Date
    attachedBrief?: Brief
    userProfile?: AppUserProfile
}

export enum BriefApplicationStatus {
    TSFReview = "TSFReview",
    InReview = "InReview",
    Shortlisted = "Shortlisted",
    NotEligible = "NotEligible",
    Unsuccessful = "Unsuccessful"
}

export interface BriefStatusUpdate {
    message: string
    status: BriefStatus
}

export const loadBrief = (brief: Brief) => {
    brief.activityType = brief.activityType?.map((a: any) => { return { label: a, value: a } });
    brief.platforms = brief.platforms?.map((p: any) => { return { label: p, value: p } });
    brief.requiredCreator && (brief.requiredCreator.gender = brief.requiredCreator.gender?.map((g: any) => { return { label: g, value: g } }));
    brief.audienceDemographics && (brief.audienceDemographics.gender = brief.audienceDemographics.gender?.map((g: any) => { return { label: g, value: g } }));

    brief.requiredCreator && (brief.requiredCreator.ages = brief.requiredCreator.ages?.map((a: any) => { return { label: a, value: a } }));
    brief.requiredCreator && (brief.requiredCreator.contentAreas = brief.requiredCreator.contentAreas?.map((c: any) => { return { label: c, value: c } }));
    brief.requiredCreator && (brief.requiredCreator.followerCount = brief.requiredCreator.followerCount?.map((f: any) => { return { label: f, value: f } }));
    brief.audienceDemographics && (brief.audienceDemographics.ages = brief.audienceDemographics.ages?.map((a: any) => { return { label: a, value: a } }));
    return brief;
}

export const setBriefForPost = (brief: Brief) => {
    brief.platforms = brief.platforms?.map((p: any) => p.value);
    brief.activityType = brief.activityType?.map((p: any) => p.value);

    brief.requiredCreator && (brief.requiredCreator.ages = brief.requiredCreator.ages?.map((p: any) => p.label));
    brief.requiredCreator && (brief.requiredCreator.contentAreas = brief.requiredCreator.contentAreas?.map((p: any) => p.value));
    brief.requiredCreator && (brief.requiredCreator.followerCount = brief.requiredCreator.followerCount?.map((p: any) => p.value));
    brief.requiredCreator && brief.requiredCreator.gender && (brief.requiredCreator.gender = brief.requiredCreator.gender.map((p: any) => p.value));

    brief.audienceDemographics && brief.audienceDemographics.gender && (brief.audienceDemographics.gender = brief.audienceDemographics.gender.map((p: any) => p.value));
    brief.audienceDemographics && brief.audienceDemographics.ages && (brief.audienceDemographics.ages = brief.audienceDemographics.ages.map((p: any) => p.label));

    return brief;
}