import { useState } from "react";
import { Button, Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { BriefApplication } from "../../models/brief";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface NewBriefApplicationProps {
  briefId: string;
  brandId: string;
  onSubmit: (b: BriefApplication) => void;
}

export default function AddBriefApplication(props: NewBriefApplicationProps) {
  const { handleSubmit, register } = useForm<BriefApplication>({ defaultValues: {} });
  const apiCall = useAuthApiCall();
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  document.title = 'The Social Fuse: New Brief Application';

  const onSubmit = async (b: BriefApplication) => {
    setIsSubmitting(true);
    let addedBriefApplication = await apiCall(`brands/${props.brandId}/briefs/${props.briefId}/applications`, 'post', b);
    setIsSubmitting(false);
    props.onSubmit(addedBriefApplication);
    // navigate(`../brands/briefs/${addedBrief.id}`);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  return (
    <Container style={{ border: '2px #333 solid', borderRadius: 2, padding: '10px 15px 30px 15px', margin: '40px auto' }}>
      <Form validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid>

          <Row>
            <Col>
              <p style={{fontStyle: 'italic', fontSize: 14}}>
                Before applying, please ensure your profile is up-to-date, with no outdated or incorrect information, to make a strong impression on brands.
              </p>
              <Form.Group className="mb-3">
                <Form.Label className="sf-label-regular">Message:</Form.Label>
                <textarea required style={{ borderRadius: 3, width: '100%', background: 'none', border: '1px #333 solid', minHeight: '200px', padding: 5 }} placeholder="Let the brand know how you can help them get their message out." {...register("message", { required: true })} />
              </Form.Group>
            </Col>
          </Row>
          {/* 
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="sf-label-regular">Rate (optional):</Form.Label>
                <Form.Control type="text" placeholder="" {...register("rate", { required: false })} /></Form.Group>
            </Col>
          </Row> */}

        </Container>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <Button size="sm" type="submit" variant="primary" disabled={isSubmitting}>
            {isSubmitting && <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." />}Apply <FontAwesomeIcon icon={faChevronRight} /> </Button>
        </div>
      </Form>
    </Container >
  );
}
