import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAlt, faHand, faMagnifyingGlass, faPaperPlane, faPencil, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import TSFTable from "../../utils/TSFTable";
import { ColumnDef, ColumnFilter, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { FilterModel, FilterType, QueryModel, QueryResultModel, SortModel } from "../../models/query";
import { Brief, BriefApplication, BriefApplicationStatus, BriefStatus, loadBrief } from "../../models/brief";
import ViewBrief from "../brief/ViewBrief";
import Status from "../brief/Status";
import AddUpdateBrief from "../brief/NewBrief";
import { useNavigate, useSearchParams } from "react-router-dom";
import ViewBriefApplication from "../brief/ViewBriefApplication";
import AddBriefApplication from "../brief/NewBriefApplication";
import EditBriefApplication from "../brief/EditBriefApplication";

export default function ApplicationsAdmin() {
  const [applications, setApplications] = useState({ data: [], total: 0 } as QueryResultModel<BriefApplication>);
  const [{ showModal, selectedApplication }, setShowModal] = useState({ showModal: false, selectedApplication: {} as BriefApplication });
  const [showEditModal, setShowEditModal] = useState(false);
  const [applicationRefresh, setApplicationRefresh] = useState(new Date().toTimeString());
  const apiCall = useAuthApiCall();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(new Date().toTimeString());
  const [loading, setLoading] = useState(true);

  let preFilters = [] as FilterModel[];
  searchParams.forEach((v, k) => {
    preFilters.push({ property: k, value: v, filterType: FilterType.StringEquals } as FilterModel)
  });

  let query: QueryModel = {
    filters: preFilters,
    sort: { property: "dateCreated", direction: "desc" } as SortModel,
    page: 0,
    pageSize: pageSize
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [apps] = await Promise.all([
        apiCall('applications/all', 'post', query)
      ]);
      setApplications(apps);
      setLoading(false);
    }
    getData();
  }, [apiCall, refresh]);

  const filterApplications = async (filters: ColumnFiltersState, sorting: SortingState, pagination: PaginationState) => {
    setLoading(true);
    setPageSize(pagination.pageSize);

    filters.forEach((f: ColumnFilter) => {
      let meta = (columns.find((c: ColumnDef<BriefApplication>) => c.id === f.id)?.meta) as any;

      if (meta?.filterType === FilterType.Custom) {
        if (f.value === "Yes") {
          query.filters.push({
            property: f.id,
            value: 'true',
            filterType: FilterType.BooleanEquals
          });
        }
        else if (f.value === "No") {
          query.filters.push({
            property: f.id,
            value: 'false',
            filterType: FilterType.BooleanEquals
          });
        }
      } else {
        query.filters.push({
          property: f.id,
          value: (f.value as string),
          filterType: meta?.filterType || FilterType.Contains
        });
      }
    });

    if (sorting.length > 0) {
      query.sort = {
        property: sorting[0].id,
        direction: sorting[0].desc ? "desc" : "asc"
      }
    }

    query.page = pagination.pageIndex;
    query.pageSize = pagination.pageSize;

    let u = await apiCall('applications/all', 'post', query);
    setApplications(u);
    setLoading(false);
  }

  const columns: ColumnDef<BriefApplication>[] = [
    {
      header: "Brand",
      accessorKey: "brandName",
      cell: (info: any) => info.getValue(),
      id: "brandName",
      footer: props => <strong>Total: {applications.total}</strong>,
    },
    {
      header: "Creator",
      accessorKey: "userProfile.lastName",
      cell: (info: any) => {
        let b = info.row.original as BriefApplication;
        return (b.userProfile ? `${b.userProfile?.firstName} ${b.userProfile?.lastName}` : '');
      },
      id: "userProfile.lastName"
    },
    {
      header: "Brief",
      accessorKey: "briefTitle",
      cell: (info: any) => info.getValue(),
      id: "briefTitle"
    },
    {
      header: "Applied",
      accessorKey: "dateCreated",
      enableColumnFilter: false,
      cell: (info: any) => new Date(info.getValue()).toLocaleDateString(),
      id: "dateCreated"
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (info: any) => <div style={{ textAlign: 'center' }}><Status status={info.getValue()} /></div>,
      id: "status",
      meta: {
        opts: Object.keys(BriefApplicationStatus),
        filterType: FilterType.StringEquals
      },
    },
    {
      header: " ",
      accessorKey: "actions",
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => {
        let b = info.row.original as BriefApplication;
        return (
          <div style={{width:105}}>
            <Button size="sm" variant={'primary'} onClick={() => { setShowModal({ showModal: true, selectedApplication: b }) }}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
            <Button style={{ marginLeft: 5 }} title="Brief" size="sm" variant="success" onClick={() => navigate(`/app/admin/briefs?id=${b.briefId}`)}><FontAwesomeIcon icon={faFileAlt} /></Button>
            <Button style={{marginLeft:5}} title="User" size="sm" variant="secondary" onClick={() => navigate(`/app/admin/users?id=${b.userId}`)}><FontAwesomeIcon icon={faUser} /></Button>
          </div>
        );
      },
      id: "actions"
    }
  ];

  return (
    <>
    <h2 style={{margin: '10px 0', borderBottom: '3px #06648C solid', textAlign: 'right', color: '#06648C'}}><FontAwesomeIcon icon={faHand} style={{fontSize: 24}} /> Applications</h2>
      {
        preFilters.length > 0 &&
        <Alert variant="info">
          Page filters applied:
          <ul style={{ margin: 0 }}>
            {
              preFilters.map((f: FilterModel) => <li>{f.property}: {f.value}</li>)
            }
          </ul>
          <div style={{ textAlign: 'right' }}>
            <Button size="sm" onClick={() => { navigate('/app/admin/applications'); setRefresh(new Date().toTimeString()) }}>Clear <FontAwesomeIcon icon={faTimes} /></Button>
          </div>
        </Alert>
      }
      
      <TSFTable 
        columns={columns} 
        data={applications.data} 
        filter={filterApplications} 
        pageCount={Math.floor(applications.total / pageSize) + 1} 
        loading={loading}  
      />
      
      <Modal show={showModal} onHide={() => setShowModal({ showModal: false, selectedApplication: selectedApplication })}>
        <Modal.Header closeButton>
          <Modal.Title>Administer Brief Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'right' }}>
            <Button size="sm" variant="secondary" onClick={() => { setShowEditModal(true) }}><FontAwesomeIcon icon={faPencil} /> Edit</Button>
          </div>
          <ViewBriefApplication adminMode={true} briefApplication={selectedApplication} />
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditBriefApplication briefApplcation={selectedApplication} onSubmit={function (b: BriefApplication): void {
            setShowModal({ showModal: false, selectedApplication: b }); setShowEditModal(false); setRefresh(new Date().toTimeString());
          } } />
        </Modal.Body>
      </Modal>
    </>
  );
}
