import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { AccountStatus, AccountType, AppUser, Gender } from "../../models/appUser";
import { ResultType, useAuthApiCall } from "../../utils/useAuthApiCall";
import ProfileView from "../ProfileView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAlt, faFileCsv, faHand, faMagnifyingGlass, faPaperPlane, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import TSFTable from "../../utils/TSFTable";
import { ColumnDef, ColumnFilter, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { FilterModel, FilterType, QueryModel, QueryResultModel, SortModel } from "../../models/query";
import ImageUploader from "../utils/ImageUploader";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function UserAdmin() {
  const [users, setUsers] = useState({ data: [], total: 0 } as QueryResultModel<AppUser>);
  const [{ showModal, selectedUser }, setShowModal] = useState({ showModal: false, selectedUser: {} as AppUser });
  const [ages, setAges] = useState([]);
  const apiCall = useAuthApiCall();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [refresh, setRefresh] = useState(new Date().toTimeString());
  const [currentQuery, setCurrentQuery] = useState({} as QueryModel);

  let preFilters = [] as FilterModel[];
  searchParams.forEach((v, k) => {
    preFilters.push({ property: k, value: v, filterType: FilterType.StringEquals } as FilterModel)
  });

  let query: QueryModel = {
    filters: preFilters,
    sort: { property: "updatedWhen", direction: "desc" } as SortModel,
    page: 0,
    pageSize: pageSize
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [users, ages] = await Promise.all([
        apiCall('user/all', 'post', query),
        apiCall('lookup/ages', 'get'),
      ]);

      users.data.forEach((u: AppUser) => {
        u.profile = u.profile || u.proposedProfile;
      });

      setUsers(users);
      setAges(ages);
      setLoading(false);
    }
    getData();

  }, [apiCall, refresh]);

  const adminUpdateUserLifeCycle = async (u: AppUser) => {
    setIsSubmitting(true);
    let updated = await apiCall(`user/${u.id}/status`, 'put', u);
    setIsSubmitting(false);
    setShowModal({ showModal: false, selectedUser: updated });

    // replace the user in the list
    let newUsers = [...users.data];
    let index = newUsers.findIndex((x: AppUser) => x.id === updated.id);
    updated.profile = updated.profile || updated.proposedProfile;
    newUsers[index] = updated;
    setUsers({ data: newUsers, total: users.total });
  }

  const filterUsers = async (filters: ColumnFiltersState, sorting: SortingState, pagination: PaginationState) => {
    setLoading(true);
    setPageSize(pagination.pageSize);

    filters.forEach((f: ColumnFilter) => {
      let meta = (columns.find((c: ColumnDef<AppUser>) => c.id === f.id)?.meta) as any;

      query.filters.push({
        property: f.id,
        value: (f.value as string),
        filterType: meta?.filterType || FilterType.Contains
      });
    });

    if (sorting.length > 0) {
      query.sort = {
        property: sorting[0].id,
        direction: sorting[0].desc ? "desc" : "asc"
      }
    }

    query.page = pagination.pageIndex;
    query.pageSize = pagination.pageSize;
    setCurrentQuery(query);

    let u = await apiCall('user/all', 'post', query);
    u.data.forEach((u: AppUser) => {
      u.profile = u.profile || u.proposedProfile;
    });
    setUsers(u);
    setLoading(false);
  }

  const exportAsCsv = async () => 
  {
    let csv = await apiCall('user/export', 'post', currentQuery, ResultType.Text);
    let blob = new Blob([csv], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'users.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }


  const columns: ColumnDef<AppUser>[] = [
    {
      header: "",
      accessorKey: "profile.picutreUrl",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info: any) => {
        let u = info.row.original as AppUser;
        return (<ImageUploader
          fileId={u.profile?.picture && `${u.id}/${u.profile.picture.id}`}
          showEdit={false}
          customKey="profile-viewer"
          additionalStyles={{ width: 40, height: 40, borderRadius: '50%', margin: '0 auto' }}
          fallbackImageUrl="/green-default-sq.png" />)
      },
      id: "profile.pictureUrl"
    },
    {
      header: "First Name",
      accessorKey: "profile.firstName",
      cell: (info: any) => info.getValue(),
      id: "profile.firstName",
      footer: props => <strong>Total {users.total}</strong>,
    },
    {
      header: "Last Name",
      accessorKey: "profile.lastName",
      cell: (info: any) => info.getValue(),
      id: "profile.lastName"
    },
    {
      header: "Gender",
      accessorKey: "profile.gender",
      cell: (info: any) => info.getValue(),
      id: "profile.gender",
      meta: {
        opts: Object.keys(Gender),
        filterType: FilterType.StringEquals
      }
    },
    {
      header: "Age",
      accessorKey: "profile.age.label",
      cell: (info: any) => info.getValue(),
      id: "profile.age.label",
      meta: {
        opts: ages.map((a: any) => a.label),
        filterType: FilterType.StringEquals
      }
    },
    {
      header: "Account Type",
      accessorKey: "accountType",
      cell: (info: any) => info.getValue(),
      id: "accountType",
      meta: {
        opts: Object.keys(AccountType),
        filterType: FilterType.StringEquals
      }
    },
    {
      header: "Sub Type",
      accessorKey: "subscriptionId",
      cell: (info: any) => info.getValue() === "trial" ? "Trial" : (info.getValue() && info.getValue().indexOf("sub") !== -1) ? "Sub" : "",
      id: "subscriptionId",
      meta: {
        opts: ["Trial", "Sub"],
        filterType: FilterType.Contains
      }
    },
    {
      header: "Status",
      accessorKey: "accountStatus",
      cell: (info: any) => info.getValue(),
      id: "accountStatus",
      meta: {
        opts: Object.keys(AccountStatus),
        filterType: FilterType.StringEquals
      }
    },
    // {
    //   header: "AQS?",
    //   accessorKey: "hasAqs",
    //   enableColumnFilter: false,
    //   enableSorting: false,
    //   cell: (info: any) => {
    //     let u = info.row.original as AppUser;
    //     if (u.profile?.socialMediaAccounts) {
    //       let hasAqs = false
    //       u.profile.socialMediaAccounts.forEach((sma) => {
    //         if (sma.aqs && sma.aqs.score) {
    //           hasAqs = true;
    //         }
    //       })
    //       return hasAqs ? <div style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faCheck} /></div> : "";
    //     }
    //   },
    //   id: "hasAqs",
    //   meta: {
    //     opts: ["Yes", "No"],
    //     filterType: FilterType.Custom
    //   }
    // },
    {
        header: "Verified?",
        accessorKey: "hasVerified",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info: any) => {
          let u = info.row.original as AppUser;
          if (u.profile?.socialMediaAccounts) {
            let hasVerified = false
            u.profile.socialMediaAccounts.forEach((sma) => {
              if (sma.verified) {
                hasVerified = true;
              }
            })
            return hasVerified ? <div style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faCheck} /></div> : "";
          }
        },
        id: "hasAqs",
        meta: {
          opts: ["Yes", "No"],
          filterType: FilterType.Custom
        }
      },
    {
      header: "Created",
      accessorKey: "createdWhen",
      enableColumnFilter: false,
      cell: (info: any) => new Date(info.getValue()).toLocaleDateString(),
      id: "createdWhen",
    },
    {
      header: "Updated",
      accessorKey: "updatedWhen",
      enableColumnFilter: false,
      cell: (info: any) => new Date(info.getValue()).toLocaleDateString(),
      id: "updatedWhen",
    },
    {
      header: " ",
      accessorKey: "actions",
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => {
        let u = info.row.original as AppUser;
        return (
          <div style={{ width: 70 }}>
            <Button title="User Profile" size="sm" variant={'primary'} onClick={() => { setShowModal({ showModal: true, selectedUser: u }) }}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
            {(u.accountType === AccountType.Brand || u.accountType === AccountType.Agency) && <Button style={{ marginLeft: 5 }} title="Briefs" size="sm" variant="success" onClick={() => navigate(`/app/admin/briefs?userId=${u.id}`)}><FontAwesomeIcon icon={faFileAlt} style={{padding: '0 2px'}} /> </Button>}
            {(u.accountType === AccountType.Creator) && <Button style={{ marginLeft: 5 }} title="Applications" size="sm" variant="danger" onClick={() => navigate(`/app/admin/applications?userId=${u.id}`)}><FontAwesomeIcon icon={faHand} /></Button>}
          </div>
        );
      },
      id: "actions",
      footer: props => <div style={{textAlign:'right'}}><Button size="sm" onClick={exportAsCsv}><FontAwesomeIcon icon={faFileCsv} /></Button></div>,
    }

  ];

  return (
    <>
      <h2 style={{ margin: '10px 0', borderBottom: '3px #C499EA solid', textAlign: 'right', color: '#C499EA' }}><FontAwesomeIcon icon={faUser} style={{fontSize: 24}} /> Users</h2>
      {
        preFilters.length > 0 &&
        <Alert variant="info">
          Page filters applied:
          <ul style={{ margin: 0 }}>
            {
              preFilters.map((f: FilterModel) => <li>{f.property}: {f.value}</li>)
            }
          </ul>
          <div style={{ textAlign: 'right' }}>
            <Button size="sm" onClick={() => { navigate('/app/admin/users'); setRefresh(new Date().toTimeString()) }}>Clear <FontAwesomeIcon icon={faTimes} /></Button>
          </div>
        </Alert>
      }

      <TSFTable
        columns={columns}
        data={users.data}
        filter={filterUsers}
        pageCount={Math.floor(users.total / pageSize) + 1}
        loading={loading}
      />

      <Modal size="xl" show={showModal} onHide={() => setShowModal({ showModal: false, selectedUser: selectedUser })}>
        <Modal.Header closeButton>
          <Modal.Title>Administer Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            <Container>
              <Row><Col><h5>Update Status & Notify User:</h5></Col></Row>
              <Row>
                <Col sm={4} style={{}}>
                  <select
                    style={{ display: 'inline-block' }}
                    className="form-select"
                    onChange={
                      (e) => {
                        let s = { ...selectedUser };
                        if (e.target.value as AccountStatus === AccountStatus.Active && s.accountStatus === AccountStatus.Updated) {
                          s.profile = s.proposedProfile;
                        }
                        s.accountStatus = e.target.value as AccountStatus;

                        setShowModal({ showModal: true, selectedUser: s })
                      }}
                    value={selectedUser.accountStatus}>
                    {
                      Object.keys(AccountStatus).map((a) => {
                        return <option key={a} value={a}>{a}</option>
                      })
                    }
                  </select>
                </Col>
                <Col sm={6}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Feedback for user if not making Active ..."
                    value={selectedUser.feedback || ""}
                    onChange={(e) => { let s = { ...selectedUser }; s.feedback = e.target.value; setShowModal({ showModal: true, selectedUser: s }) }} />
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button size="sm" disabled={isSubmitting} variant="success" onClick={() => { adminUpdateUserLifeCycle(selectedUser) }}>
                    {isSubmitting ? <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." /> : <FontAwesomeIcon icon={faPaperPlane} />} Update</Button>
                </Col>
              </Row>
            </Container>
          </Alert>
          <ProfileView
            appUser={selectedUser}
            appUserProfile={selectedUser.profile}
            showEditButton={false}
            adminMode={true}
            submitCallback={(u: AppUser) => {
              // replace the user in the list
              let newUsers = [...users.data];
              let index = newUsers.findIndex((x: AppUser) => x.id === u.id);
              u.profile = u.profile || u.proposedProfile;
              newUsers[index] = u;
              setUsers({ data: newUsers, total: users.total });
              setShowModal({ showModal: true, selectedUser: u })
            }}
          />
          {
            selectedUser.accountStatus === AccountStatus.Updated &&
            <>
              <hr className="my-4" />
              <h5>Updated Profile</h5>
              <ProfileView
                appUser={selectedUser}
                appUserProfile={selectedUser.proposedProfile}
                showEditButton={false}
              />
            </>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
