import { useEffect, useState } from "react";
import { Button, Col, Form, Container, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { Brief, BriefApplication, BriefApplicationStatus } from "../../models/brief";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AccountType, SocialMediaAccount } from "../../models/appUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import ProfileView from "../ProfileView";
import Status from "./Status";


export default function BriefApplications() {

  const [applications, updateApplications] = useState([] as Array<BriefApplication>);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('' as string);
  const [selectedApplication, setSelectedApplication] = useState({} as BriefApplication);
  const [brief, setBrief] = useState({} as Brief);
  const { brandId } = useParams();
  const { briefId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiCall = useAuthApiCall();
  const navigate = useNavigate();
  document.title = 'The Social Fuse: Brief Applications';

  useEffect(() => {
    // load applications
    const getApplications = async () => {
      const apps = await apiCall(`brands/${brandId}/briefs/${briefId}/applications`, 'get');
      updateApplications(apps || []);
      setIsLoading(false);
    }

    const getBrief = async () => {
      const brief = await apiCall(`brands/${brandId}/briefs/${briefId}`, 'get');
      setBrief(brief);
      document.title = `The Social Fuse: Applications for ${brief.title}`;
    }

    getBrief();
    getApplications();
  }, [apiCall, brandId, briefId]);

  const approveRejectApp = async (appId: string, status: BriefApplicationStatus) => {
    setIsSubmitting(true)
    const app = await apiCall(`brands/${brandId}/briefs/${briefId}/applications/${appId}/approve`, 'post', { status: status, message: (document.getElementById('message') as HTMLTextAreaElement).value, rejectionReason: rejectionReason });
    if (app) {
      let apps = [...applications];
      let index = apps.findIndex((a: BriefApplication) => a.id === appId);
      if (index > -1) {
        apps[index] = app;
        updateApplications(apps);
      }
      setShowApproveModal(false);
      setShowRejectModal(false);
    }
    setIsSubmitting(false);
  }

  return (
    <>
      <Container style={{ margin: '0 auto 40px auto' }}>
        <Row>
          <Col>
            <Button size="sm" variant="" style={{ marginTop: 20 }} onClick={() => navigate(`../brands/${brandId}/briefs`)}><FontAwesomeIcon icon={faChevronLeft} /> Back to Dashboard</Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: 30 }}>
          <Col md={{ offset: 2, span: 8 }} xs={6}>
            <h2 style={{ textAlign: 'center' }}><span className="sf-underline sf-blue-underline">Brief Applications</span></h2>
          </Col>
        </Row>
        <Row style={{ padding: '0 12px', marginBottom: 20 }}>
          <Col className="sf-green-fill" style={{ padding: '10px', borderRadius: '4px 0 0 4px' }}>
            Brief Title: {brief.title}
          </Col>
          <Col className="sf-green-fill" style={{ padding: '10px' }}>
            Date Posted: {new Date(brief.dateCreated).toLocaleDateString('en-GB')}
          </Col>
          <Col className="sf-green-fill" style={{ padding: '10px' }}>
            Status: <Status status={brief.briefStatus} />
          </Col>
          <Col className="sf-green-fill" style={{ padding: '10px', borderRadius: '0 4px 4px 0' }}>
            Applicants: {brief.applicationCount} / 30
          </Col>

        </Row>
        <Row>
          <Col>
            {
              isLoading && <div style={{ textAlign: 'center', margin: 20 }}>
                <Spinner style={{}} animation="border" placeholder="Loading Brief Applications..." />
              </div>
            }
            {
              !isLoading && applications.length === 0 &&
              <div style={{ textAlign: 'center', margin: 20 }}>
                <p>No applications for this brief (yet)</p>
              </div>
            }
            {
              applications.length > 0 &&
              <Table borderless>
                <thead style={{ backgroundColor: '#333', color: '#fff', border: '2px #333 solid' }}>
                  <tr>
                    <th colSpan={2}>
                      Applicant
                    </th>
                    <th>
                      Socials
                    </th>
                    <th>
                      Date Applied
                    </th>
                    <th>
                      Message
                    </th>
                    {/* <th>
                      Rate
                    </th> */}
                    <th style={{ textAlign: 'center' }}>
                      Approval Status
                    </th>
                  </tr>
                </thead>
                <tbody style={{ border: '2px #333 solid', borderRadius: 5, backgroundColor: '#fff' }}>
                  {
                    applications.map((a: BriefApplication) => (
                      <tr key={a.id}>
                        <td style={{ width: 40 }}>
                          <Button size="sm" title="View User Profile" variant="secondary" onClick={() => { setSelectedApplication(a); setShowProfileModal(true) }}><FontAwesomeIcon icon={faUser} /></Button>
                        </td>
                        <td style={{ padding: '15px' }}>
                          {a.userProfile?.firstName} {a.userProfile?.lastName}
                        </td>
                        <td>
                          {a.userProfile?.socialMediaAccounts?.map((s: SocialMediaAccount, i: number) => (
                            <div key={i}>{s.site} ({s.followerCount.label})</div>
                          ))
                          }
                        </td>
                        <td>
                          {new Date(a.dateCreated).toLocaleDateString('en-GB')}
                        </td>
                        <td style={{ maxWidth: 200 }}>
                          {
                            a.message.length > 100 ?
                              <div>
                                {a.message.substring(0, 100)}...
                                <div style={{ textAlign: 'right' }}>
                                  <Link to="" style={{ fontSize: 12 }} onClick={() => { setSelectedMessage(a.message); setShowMessageModal(true) }}>[Show message]</Link>
                                </div>
                              </div>
                              :
                              a.message
                          }
                        </td>
                        {/* <td>{a.rate}</td> */}
                        <td style={{ textAlign: 'center' }}>
                          {
                            a.status === BriefApplicationStatus.InReview ?
                              <>
                                <Button variant="success" size="sm" onClick={() => { setSelectedApplication(a); setRejectionReason(''); setShowApproveModal(true) }}><FontAwesomeIcon icon={faCheck} /> Shortlist</Button>
                                <Button variant="warning" style={{ marginLeft: 10 }} size="sm" onClick={() => { setSelectedApplication(a); setRejectionReason(''); setShowRejectModal(true) }}><FontAwesomeIcon icon={faTimes} /> Decline</Button>
                              </>
                              :
                              <Status status={a.status} />
                          }
                        </td>
                      </tr>))
                  }
                </tbody>
              </Table>
            }
          </Col>
        </Row>
      </Container>
      <Modal show={showMessageModal} onHide={() => setShowMessageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Application Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMessage}
        </Modal.Body>
      </Modal>
      <Modal show={showApproveModal} onHide={() => setShowApproveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Shortlist Application: {selectedApplication.userProfile?.firstName} {selectedApplication.userProfile?.lastName} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">Once you shortlist this application, the creator will be notified of your decision.</p>
          <p>The creator's contact details will be shared with you. This is not a binding decision. You will have further opportunity to discuss and negotiate with them directly.</p>
          <p style={{ textAlign: 'center', paddingTop: 20 }}>
            <textarea id="message" name="message" rows={4} style={{ width: '100%', borderRadius: 4, padding: 10, marginBottom: 20 }} placeholder="Enter a message to the creator (optional)"></textarea>
            <Button disabled={isSubmitting} variant="success" size="sm" onClick={() => { approveRejectApp(selectedApplication.id, BriefApplicationStatus.Shortlisted) }}><FontAwesomeIcon icon={faCheck} /> Shortlist</Button>
          </p>
        </Modal.Body>
      </Modal>
      <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Decline Application: {selectedApplication.userProfile?.firstName} {selectedApplication.userProfile?.lastName} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">Once you decline this application, the creator will be notified of your decision.</p>
          <p style={{ textAlign: 'center', paddingTop: 20 }}>
            <Form.Group className="mb-3">
              <select onChange={(e) => setRejectionReason(e.target.value)} id="rejectionReason" name="rejectionReason" className="form-select" style={{ width: '100%', padding: 10, marginBottom: 20 }}>
                <option value="">Select a reason for declining *</option>
                <option value="Audience demographics don't match campaign requirements">Audience demographics don't match campaign requirements</option>
                <option value="Seeking different content style or theme">Seeking different content style or theme</option>
                <option value="Engagement rates below campaign's required threshold">Engagement rates below campaign's required threshold</option>
                <option value="Follower count below campaign's minimum requirement">Follower count below campaign's minimum requirement</option>
                <option value="Past collaborations/content not aligned with our brand values">Past collaborations/content not aligned with our brand values</option>
                <option value="Already secured enough creators for this campaign">Already secured enough creators for this campaign</option>
                <option value="Other">Other (please specify below)</option>
              </select>
            </Form.Group>
            <textarea id="message" name="message" rows={4} style={{ width: '100%', borderRadius: 4, padding: 10, marginBottom: 20 }} placeholder="Enter a message to the creator (optional)"></textarea>
            <Button disabled={rejectionReason === "" || isSubmitting} variant="warning" size="sm" onClick={() => { approveRejectApp(selectedApplication.id, BriefApplicationStatus.Unsuccessful) }}><FontAwesomeIcon icon={faTimes} /> Decline</Button>
          </p>
        </Modal.Body>
      </Modal>
      <Modal show={showProfileModal} size="xl" onHide={() => setShowProfileModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Applicant Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProfileView appUser={{ id: selectedApplication.userId, accountType: AccountType.Creator }} appUserProfile={selectedApplication.userProfile} showEditButton={false} />
        </Modal.Body>
      </Modal>
    </>
  );

}
